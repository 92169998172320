import React from 'react';

import gsap from 'gsap';

import '@app/scss/components/scrollSection.scss';
import ElementWithIntersectionObserver
	from '@app/components/UI/components/ElementWithIntersectionObserver/ElementWithIntersectionObserver';

interface Props {
	text: React.ReactNode;
	button: React.ReactNode;
}

const LineWithText: React.FC<{text, className?: string}> = ({text, className}) => {
	const lineRef = React.useRef<HTMLDivElement>(null);
	const textRef = React.useRef<HTMLDivElement>(null);
	
	React.useEffect(() => {
		if (lineRef.current) {
			gsap.to(lineRef.current, {
				scrollTrigger: {
					trigger: lineRef.current.closest('.animation-item'),
					start: 'top center',
					end: 'bottom center',
					scrub: true
				},
				ease: 'none',
				y: 0
			});
		}
		
		if (textRef.current) {
			gsap.to(textRef.current, {
				scrollTrigger: {
					trigger: textRef.current,
					start: 'top 80%',
					end: 'bottom 60%',
					scrub: true
				},
				ease: 'none',
				opacity: 1
			});
		}
	}, []);

	return <div className="animation-item">
		<div className="outer-line-wrapper">
			<div className={`line-wrapper ${className}`} ref={lineRef}>
				<div className="vertical-line"/>
				<div className="line-circle"/>
			</div>
		</div>
		<div className="animation-item-text" ref={textRef}>
			{text}
		</div>
	</div>;
};

const items = [
	{className: '', text: 'ONLINE DMV SERVICES'},
	{className: 'line-4', text: 'FEE QUOTES'},
	{className: 'line-3', text: 'DMS INTEGRATION'},
	{className: 'line-5', text: 'NMVITIS'},
	{className: 'line-3', text: 'DEALER TRAINING'},
	{className: 'line-4', text: 'LIVE SUPPORT'}
];

const LineWithTextMobile: React.FC<{text, className?: string}> = ({text, className}) => {
	return <ElementWithIntersectionObserver
		className="benefit-mobile smaller-margin"
		inner={show => 
			<div className={`wrapper-2 horizontal align-center ${show ? 'show-line' : ''}`}>
				<div className={`benefit-line-mobile ${className}`}>
					<div className="benefit-horizontal-line"/>
					<div className="benefit-circle"/>
				</div>
				<div className="experience-wrapper">
					<div className="animation-item-text">{text}</div>
				</div>
			</div>}
	/>;
};

const ScrollSection:React.FC<Props> = ({text, button}) => {
	const textRef = React.useRef<HTMLDivElement>(null);
	
	React.useEffect(() => {
		if (textRef.current) {
			gsap.to(textRef.current, {
				scrollTrigger: {
					trigger: textRef.current,
					start: 'top top',
					end: () => {
						const height = textRef.current
							?.closest('.home-services-container')
							?.getBoundingClientRect()
							?.height;
						return `+=${height}`;
					},
					scrub: true
				},
				ease: 'none',
				x: 50
			});
		}
	}, []);
	
	return <div>
		<div className="section-2 desktop-no-padding-top wf-section">
			<div className="wide-container home-services">
				<div className="home-services-container">
					<div className="full-height-text-container">
						<div className="section-text-container">
							<div className="section-title-wrapper">
								<h2 className="heading-3 absolute">Services</h2>
								<div className="section-outlined-heading outlined" ref={textRef}>Services</div>
								<div className="section-outlined-heading-trigger"/>
							</div>
							<div className="line-animations-container-mobile home-services">
								{items.map((item, key) => <LineWithTextMobile {...item} key={key} />)}
							</div>
							<div className="section-paragraph-wrapper center-on-tablet">
								<p className="paragraph">{text}</p>
							</div>
							{button}
						</div>
					</div>
					<div className="home-services-animation-container">
						<div className="animation-line-column">
							<LineWithText
								text={<>
								<strong>DMV50</strong><br/><strong>PLATFORM</strong>
								</>}
							/>
							<LineWithText
								className="short"
								text={<>
									<strong>DMS</strong> <strong>INTEGRATION</strong>
								</>}
							/>
							<LineWithText
								className="short"
								text={<>
									<strong>DEALER</strong> <strong>TRAINING</strong>
								</>}
							/>
						</div>
						<div className="animation-line-column">
							<LineWithText
								className="long"
								text={<>
									<strong>FEE QUOTES</strong>
								</>}
							/>
							<LineWithText
								className="short"
								text={<>
									<strong>NMVITIS</strong>
								</>}
							/>
							<LineWithText
								className="short"
								text={<>
									<strong>LIVE</strong> <strong>SUPPORT</strong>
								</>}
							/>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>;
};

export default ScrollSection;
