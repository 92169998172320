import React from 'react';
import Lottie from 'react-lottie';

import gsap from 'gsap';

const defaultOptions = {
	loop: true,
	autoplay: true,
	animationData: require('./1.json'),
	rendererSettings: {
		preserveAspectRatio: 'xMidYMid slice'
	}
};

const HeroSection: React.FC = () => {
	const ref = React.useRef<HTMLDivElement>(null);
	const image = React.useRef<HTMLImageElement>(null);
	const text1ref = React.useRef<HTMLDivElement>(null);
	const text2ref = React.useRef<HTMLDivElement>(null);
	const text3ref = React.useRef<HTMLDivElement>(null);
	const text4ref = React.useRef<HTMLDivElement>(null);
	/* const text5ref = React.useRef<HTMLDivElement>(null);
	const text6ref = React.useRef<HTMLDivElement>(null); */

	React.useEffect(() => {
		const container = ref.current?.closest('.section-hero');
		if (ref.current) {
			gsap.to(ref.current, {
				scrollTrigger: {
					trigger: container,
					start: 'top top',
					end: 'bottom bottom',
					scrub: true
				},
				ease: 'none',
				width: () => window.innerWidth
			});
		}
		
		[{ref: image, x: 0.445}, {ref: text1ref, x: -0.7}, {ref: text2ref, x: -0.5}, {ref: text3ref, x: -0.7}, {ref: text4ref, x: -0.5}]
			.forEach(({ref, x}) => {
				if (ref.current) {
					gsap.to(ref.current, {
						scrollTrigger: {
							trigger: container,
							start: 'top top',
							end: 'bottom bottom',
							scrub: true
						},
						ease: 'none',
						x: () => window.innerWidth * x
					});
				}
			});

		/*[{ref: text5ref, x: -0.7}, {ref: text6ref, x: -0.5}]
			.forEach(({ref, x}) => {
				if (ref.current) {
					gsap.to(ref.current, {
						scrollTrigger: {
							trigger: container,
							start: 'top top',
							end: 'bottom bottom',
							scrub: true
						},
						ease: 'none',
						x: () => window.innerWidth * x
					});
				}
			});*/
	}, []);

	return <div className="section-hero">
		<div className="main-hero-section-content">
			<div className="container main-hero-container">
				<div className="hero-full-text-panel">
					<div className="hero-hello-text" ref={text1ref}>
						<div className="perspective">
							<div className="perspective-load1">
								<h1 className="hero-text hero"><span className="text-span clipped"><strong>A</strong> #1 TAG</span> & TITLE AGENCY
								</h1>
							</div>
						</div>
					</div>
					<div className="hero-text-content" ref={text2ref}>
						<div className="perspective">
							<div className="perspective-load2">
								<h1 className="hero-text hero">ALL 50 STATES</h1>
							</div>
						</div>
					</div>
				</div>
				<div className="w-layout-grid hero-grid">
					<div className="hero-left-description-content">
						<div className="perspective">
							<div className="perspective-load5">
								<h3 className="hero-description big">LEADING THE INDUSTRY</h3>
							</div>
						</div>
					</div>
					<div className="hero-links-grid">
						<div className="content">
							<div className="perspective">
								<div className="perspective-load6">
									<a
										href="mailto:hi@mywebsite.com?subject=Webflow%20Template"
										className="link w-inline-block">
										<div>sales@executivetags.com</div>
										<div className="link-underline"/>
									</a>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="lottie-div">
					<Lottie
						options={defaultOptions}
						height={500}
						width={500}
						isStopped={false}
						isPaused={false}
					/>
				</div>
			</div>
			<div className="hero-image-white-text" ref={ref}>
				<picture>
					<source media="(max-width: 800px)" srcSet="/images/executivetags-hero-two-p-500.png"/>
					<img
						className="hero-picture"
						alt="hero"
						aria-hidden="true"
						src="/images/executivetags-hero-two.png"
						ref={image}
					/>
				</picture>
				<div className="hero-black-gradient"/>
				<div className="hero-container-content">
					<div className="container main-hero-container">
						<div className="hero-full-text-panel" style={{paddingRight: '1rem'}}>
							<div className="hero-hello-text" ref={text3ref}>
								<h1 className="hero-text white-hello"><strong>A</strong> #1 TAG & TITLE AGENCY</h1>
							</div>
							<div className="hero-text-content" ref={text4ref}>
								<h1 className="hero-text white-text">ALL 50 STATES</h1>
							</div>
						</div>
						<div className="w-layout-grid hero-grid">
							<div className="hero-links-grid">
								<div>
								<div className="">
									<a
										href="https://www.linkedin.com/company/executive-tag-&amp;-title-svcs.-inc./"
										target="_blank"
										className="link w-inline-block"
									>
										<div className="white-text">LinkedIn</div>
										<div className="link-underline white"/>
									</a>
								</div>
								<div className="">
									<a
										href="https://www.instagram.com/executivetags/?hl=de" target="_blank"
										className="link w-inline-block"
									>
										<div className="white-text">Instagram</div>
										<div className="link-underline white"/>
									</a>
								</div>
								<div className="">
									<a
										href="https://www.facebook.com/executivetags/" target="_blank"
										className="link w-inline-block">
										<div className="white-text">Facebook</div>
										<div className="link-underline white"/>
									</a>
								</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>;
};

export default HeroSection;
