import * as React from 'react';
import {NavLink} from 'react-router-dom';

import '@app/scss/pages/home.scss';
import '@app/scss/components/banner.scss';
import NewContactForm from '@app/components/UI/ContactForm/NewContactForm';
import HeroSection from '@app/components/Pages/Home/HeroSection';
import ScrollSection from '@app/components/UI/ScrollSection';
import ThirdSection from '@app/components/Pages/Home/ThirdSection';

const Home: React.FC = () => {
	return (
		<div className="home-page home">
			<HeroSection/>
			<ScrollSection
				text={<>
					As the industry's fastest-growing agency, we offer customers and auto dealers in all 50 states smooth and
					efficient DMV services related to title and registration. Our exceptional client service and easy, straightforward
					process set our services apart, backed by 30 years of <strong>Combined</strong> expertise. Service your transaction or request a
					demo of our platform today!
				</>}
				button={<NavLink to="/contact-us" className="primary-button-2 w-inline-block">
					<div className="wrapper-2">
						<div className="primary-button-text">Demo Now</div>
					</div>
					<div className="primary-button-shadow-2"/>
				</NavLink>}
			/>
			<ThirdSection/>
			<NewContactForm title="How can we help?" withGradient/>
		</div>);
};

export default Home;
