import React from 'react';

import gsap from 'gsap';
import CustomEase from 'gsap/CustomEase';

import ElementWithIntersectionObserver
	from '@app/components/UI/components/ElementWithIntersectionObserver/ElementWithIntersectionObserver';

interface Props {}

// @ts-ignore
const ease = CustomEase.create('custom', 'M0,0,C0,0,0.15,0.24,0.2,0.25,0.254,0.26,0.24,0.256,0.294,0.268,0.372,0.285,0.382,0.467,0.46,0.488,0.506,0.5,0.482,0.492,0.53,0.502,0.597,0.516,0.651,0.724,0.718,0.74,0.768,0.752,0.76,0.744,0.786,0.754,0.83,0.771,0.89,0.866,0.912,0.888,0.933,0.909,1,1,1,1');

const Icon: React.FC<{image, small?: boolean}> = ({image, small}) => {
	const ref = React.useRef<HTMLImageElement>(null);
	const handleScroll = () => {
		if (ref.current) {
			const {top, height} = ref.current.getBoundingClientRect();
			const center = top + height / 2;
			let opacity = 1  - 2 * Math.abs(center - window.innerHeight / 2) / window.innerHeight;

			if (opacity < 0.05) opacity = 0.05;
			ref.current.style.opacity = `${opacity}`;
		}
	};

	return <ElementWithIntersectionObserver
		onScroll={handleScroll}
		className="service-icon-content"
		inner={show => 
			<img
				ref={ref}
				src={image}
				alt="executivetags-icon"
				className={`service-icon ${small ? 'icon-small' : ''}`}/>
		}
	/>;
};

const ThirdSection: React.FC<Props> = ({}) => {
	const ref = React.useRef<HTMLDivElement>(null);
	
	React.useEffect(() => {
		const container = ref.current?.closest('.no-max-width');
		if (ref.current) {
			gsap.to(ref.current, {
				scrollTrigger: {
					trigger: container,
					start: 'top top',
					end: 'bottom bottom',
					scrub: true
				},
				ease,
				y: () => (ref.current?.getBoundingClientRect()?.height || window.innerHeight) * 2 / 3
			});
		}
	}, []);

	return <div className="section services hide">
		<div className="container no-max-width">
			<div className="w-layout-grid grid-services">
				<div className="service-icons" ref={ref}>
					<Icon image="images/smartphone-icon.svg"/>
					<Icon image="images/60ffcb39b87870207c6e4ba9_Anebot.svg" small/>
					<Icon image="images/60ffcb3a12374a1ea81b2e42_Anfrage-SVG.svg" small/>
				</div>
				<div className="service-list-content">
					<div className="service-item">
						<div className="service-text-content">
							<div className="perspective">
								<div className="perspective-load1-on-view">
									<h2 className="service-title">Demo Now</h2>
								</div>
							</div>
							<div className="service-no">01</div>
						</div>
					</div>
					<div className="service-item">
						<div className="service-text-content">
							<div className="perspective">
								<div className="perspective-load1-on-view">
									<h2 className="service-title">FAST</h2>
								</div>
								<div className="perspective-load2-on-view">
									<h2 className="service-title">ACCESS</h2>
								</div>
							</div>
							<div className="service-no">02</div>
						</div>
					</div>
					<div className="service-item">
						<div className="service-text-content">
							<div className="perspective">
								<div className="perspective-load1-on-view">
									<h2 className="service-title">START</h2>
								</div>
								<div className="perspective-load2-on-view">
									<h2 className="service-title">USING</h2>
								</div>
							</div>
							<div className="service-no">03</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div className="background-black"/>
	</div>;
};

export default ThirdSection;
